import { CommonModule } from '@angular/common';
import { Component, OnDestroy } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TranslationsService } from '@goatsports/shared/util';
import { TranslocoModule } from '@jsverse/transloco';
import { combineLatest } from 'rxjs';
import { ErrorService } from '../../../services/error.service';

@Component({
  selector: 'goatsports-validation-errors',
  templateUrl: './validation-errors.component.html',
  styleUrls: ['./validation-errors.component.scss'],
  standalone: true,
  imports: [CommonModule, MatFormFieldModule, TranslocoModule],
})
export class ValidationErrorsComponent implements OnDestroy {
  errors$ = this.errorService.validationErrors$;
  lang$ = this.translationsService.selectedLanguage$;
  combinedStream$ = combineLatest([this.errors$, this.lang$]);

  constructor(
    private errorService: ErrorService,
    private translationsService: TranslationsService
  ) {}

  ngOnDestroy() {
    this.errorService.clearServerErrors();
  }
}
