import { CommonModule } from '@angular/common';
import { Component, OnDestroy } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ErrorService } from '@goatsports/shared/util';
import { TranslocoModule } from '@jsverse/transloco';

@Component({
  selector: 'goatsports-error-messages',
  templateUrl: './error-messages.component.html',
  styleUrls: ['./error-messages.component.scss'],
  standalone: true,
  imports: [CommonModule, MatFormFieldModule, TranslocoModule],
})
export class ErrorMessagesComponent implements OnDestroy {
  errorMessage$ = this.errorService.errorMessage$;

  constructor(private errorService: ErrorService) {}

  ngOnDestroy() {
    this.errorService.clearServerErrors();
  }
}
