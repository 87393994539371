import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'goatsports-submit-btn',
  standalone: true,
  imports: [CommonModule, MatButtonModule],
  templateUrl: './submit-btn.component.html',
  styleUrls: ['./submit-btn.component.scss'],
})
export class SubmitBtnComponent {
  @Output() submitBtnClicked = new EventEmitter();
  @Input() isWaiting = false;
  @Input() text!: string;
  @Input() type: 'submit' | 'button' = 'submit';

  submit() {
    this.submitBtnClicked.emit();
  }
}
