<button
  mat-flat-button
  [ngClass]="{ spinner: isWaiting }"
  [disabled]="isWaiting"
  [type]="type"
  (click)="submit()"
  class="submit-btn"
>
  {{ text }}
</button>
